import {
  CREATE_KATEGORI_SUCCESS,
  CREATE_KATEGORI_FAIL,
  RETRIEVE_KATEGORI_SUCCESS,
  RETRIEVE_KATEGORI_FAIL,
  UPDATE_KATEGORI_SUCCESS,
  UPDATE_KATEGORI_FAIL,
  DELETE_KATEGORI_SUCCESS,
  DELETE_KATEGORI_FAIL,
} from "../actions/types";

const initialState = [];

function kategoriReducer(kategoris = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_KATEGORI_SUCCESS:
      return [...kategoris, payload];

    case RETRIEVE_KATEGORI_SUCCESS:
      return payload;

    case RETRIEVE_KATEGORI_FAIL:
      return [...kategoris];

    case UPDATE_KATEGORI_SUCCESS:
      return kategoris.map((kategori) => {
        if (kategori.id === payload.id) {
          return {
            ...kategoris,
            ...payload,
          };
        } else {
          return kategori;
        }
      });

    case DELETE_KATEGORI_SUCCESS:
      return kategoris.filter(({ id }) => id !== payload.id);

    default:
      return kategoris;
  }
}

export default kategoriReducer;
