import {
  CREATE_PRESENSI_SUCCESS,
  CREATE_PRESENSI_FAIL,
  RETRIEVE_PRESENSI_SUCCESS,
  RETRIEVE_PRESENSI_FAIL,
  UPDATE_PRESENSI_SUCCESS,
  UPDATE_PRESENSI_FAIL,
  DELETE_PRESENSI_SUCCESS,
  DELETE_PRESENSI_FAIL,
} from "../actions/types";

const initialState = [];

function presensiReducer(presensis = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PRESENSI_SUCCESS:
      return [...presensis, payload];

    case RETRIEVE_PRESENSI_SUCCESS:
      return payload;

    case RETRIEVE_PRESENSI_FAIL:
      return [...presensis];

    case UPDATE_PRESENSI_SUCCESS:
      return presensis.map((presensi) => {
        if (presensi.uuid === payload.uuid) {
          return {
            ...presensis,
            ...payload,
          };
        } else {
          return presensi;
        }
      });

    case DELETE_PRESENSI_SUCCESS:
      return presensis.filter(({ id }) => id !== payload.id);

    default:
      return presensis;
  }
}

export default presensiReducer;
