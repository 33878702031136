import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import LoggedArea from "./utils/LoggedArea";
import AdminArea from "./utils/AdminArea";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

//Auth
const Login = Loader(lazy(() => import("src/pages/auth/Login")));
const Logout = Loader(lazy(() => import("src/pages/auth/Logout")));
// Pages
const LandingPage = Loader(lazy(() => import("src/pages/landingpage")));

// Status

const Status404 = Loader(
  lazy(() => import("src/pages/landingpage/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/pages/landingpage/Status/Status500"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/pages/landingpage/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/pages/landingpage/Status/Maintenance"))
);

const Overview = Loader(lazy(() => import("src/pages/overview")));
// Dashboards
const Crypto = Loader(lazy(() => import("src/pages/dashboards/Crypto")));
const Messenger = Loader(
  lazy(() => import("src/pages/applications/Messenger"))
);

// Systems
const User = Loader(lazy(() => import("src/pages/systems/User")));
const Settings = Loader(lazy(() => import("src/pages/systems/Settings")));

// Akun
const UserProfile = Loader(lazy(() => import("src/pages/akun/profile")));
const UserSettings = Loader(lazy(() => import("src/pages/akun/settings")));
// Managements
const Kunjungan = Loader(lazy(() => import("src/pages/managements/Kunjungan")));
const Lokasi = Loader(lazy(() => import("src/pages/managements/Lokasi")));
const Pegawai = Loader(lazy(() => import("src/pages/managements/Pegawai")));
const ReportType = Loader(
  lazy(() => import("src/pages/managements/ReportType"))
);
const WareHouseType = Loader(
  lazy(() => import("src/pages/managements/WareHouseType"))
);
const WorkingType = Loader(
  lazy(() => import("src/pages/managements/WorkingType"))
);
const JenisPresensi = Loader(
  lazy(() => import("src/pages/managements/JenisPresensi"))
);

// Transactions
const BukuTamu = Loader(lazy(() => import("src/pages/transactions/BukuTamu")));
const Ijin = Loader(lazy(() => import("src/pages/transactions/Ijin")));
const Kehadiran = Loader(
  lazy(() => import("src/pages/transactions/Kehadiran"))
);
const Kendaraan = Loader(
  lazy(() => import("src/pages/transactions/Kendaraan"))
);
const Laporan = Loader(lazy(() => import("src/pages/transactions/Laporan")));
const Paket = Loader(lazy(() => import("src/pages/transactions/Paket")));
const Patroli = Loader(lazy(() => import("src/pages/transactions/Patroli")));
const Presensi = Loader(lazy(() => import("src/pages/transactions/Presensi")));
const Sos = Loader(lazy(() => import("src/pages/transactions/Sos")));
const WorkingInstruction = Loader(
  lazy(() => import("src/pages/transactions/WorkingInstruction"))
);

const TKunjungan = Loader(
  lazy(() => import("src/pages/transactions/Kunjungan"))
);

const Parkir = Loader(lazy(() => import("src/pages/transactions/Parkir")));

const routes = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "auth",
        children: [
          {
            path: "",
            element: <Navigate to="login" replace />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "logout",
            element: <Logout />,
          },
        ],
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
          {
            path: "500",
            element: <Status500 />,
          },
          {
            path: "maintenance",
            element: <StatusMaintenance />,
          },
          {
            path: "coming-soon",
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
  {
    path: "overview",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Overview />,
      },
    ],
  },
  {
    path: "systems",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="settings" replace />,
      },
      {
        path: "settings",
        element: (
          <AdminArea>
            <Settings />
          </AdminArea>
        ),
      },
      {
        path: "user",
        element: (
          <AdminArea>
            <User />
          </AdminArea>
        ),
      },
    ],
  },
  {
    path: "akun",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="profile" replace />,
      },
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Navigate to="settings" replace />,
          },
          /*
          {
            path: "details",
            element: <UserProfile />,
          },
          */
          {
            path: "settings",
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
  {
    path: "dashboards",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="messenger" replace />,
      },

      {
        path: "messenger",
        element: <Messenger />,
      },
    ],
  },
  {
    path: "managements",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="lokasi" replace />,
      },
      {
        path: "lokasi",
        element: <Lokasi />,
      },
      {
        path: "jenis-presensi",
        element: (
          <AdminArea>
            <JenisPresensi />
          </AdminArea>
        ),
      },
      {
        path: "kunjungan",
        element: (
          <AdminArea>
            <Kunjungan />
          </AdminArea>
        ),
      },
      {
        path: "pegawai",
        element: (
          <AdminArea>
            <Pegawai />
          </AdminArea>
        ),
      },
      {
        path: "report-type",
        element: <ReportType />,
      },
      {
        path: "warehouse-type",
        element: <WareHouseType />,
      },
      {
        path: "working-type",
        element: <WorkingType />,
      },
    ],
  },
  {
    path: "transactions",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="bukutamu" replace />,
      },
      {
        path: "bukutamu",
        element: <BukuTamu />,
      },
      {
        path: "ijin",
        element: <Ijin />,
      },
      {
        path: "kehadiran",
        element: <Kehadiran />,
      },
      {
        path: "kunjungan",
        element: <TKunjungan />,
      },
      /*
      {
        path: "kendaraan",
        element: <Kendaraan />,
      },
      */ {
        path: "laporan",
        element: <Laporan />,
      },
      {
        path: "paket",
        element: <Paket />,
      },
      {
        path: "parkir",
        element: <Parkir />,
      },
      {
        path: "patroli",
        element: <Patroli />,
      },
      {
        path: "presensi",
        element: <Presensi />,
      },
      {
        path: "sos",
        element: <Sos />,
      },
      {
        path: "wi",
        element: <WorkingInstruction />,
      },
    ],
  },
];

export default routes;
