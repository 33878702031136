import {
  CREATE_PATROLI_SUCCESS,
  CREATE_PATROLI_FAIL,
  RETRIEVE_PATROLI_SUCCESS,
  RETRIEVE_PATROLI_FAIL,
  UPDATE_PATROLI_SUCCESS,
  UPDATE_PATROLI_FAIL,
  DELETE_PATROLI_SUCCESS,
  DELETE_PATROLI_FAIL,
} from "../actions/types";

const initialState = [];

function patroliReducer(datas = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PATROLI_SUCCESS:
      return [...datas, payload];

    case RETRIEVE_PATROLI_SUCCESS:
      return payload;

    case RETRIEVE_PATROLI_FAIL:
      return [...datas];

    case UPDATE_PATROLI_SUCCESS:
      return datas.map((data) => {
        if (data.uuid === payload.uuid) {
          return {
            ...datas,
            ...payload,
          };
        } else {
          return data;
        }
      });

    case DELETE_PATROLI_SUCCESS:
      return datas.filter(({ id }) => id !== payload.id);

    default:
      return datas;
  }
}

export default patroliReducer;
