import {
  CREATE_CEK_SAMPAH_SUCCESS,
  CREATE_CEK_SAMPAH_FAIL,
  RETRIEVE_CEK_SAMPAH_SUCCESS,
  RETRIEVE_CEK_SAMPAH_FAIL,
  UPDATE_CEK_SAMPAH_SUCCESS,
  UPDATE_CEK_SAMPAH_FAIL,
  DELETE_CEK_SAMPAH_SUCCESS,
  DELETE_CEK_SAMPAH_FAIL,
} from "../actions/types";

const initialState = [];

function kendaraanReducer(kendaraans = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CEK_SAMPAH_SUCCESS:
      return [...kendaraans, payload];

    case RETRIEVE_CEK_SAMPAH_SUCCESS:
      return payload;

    case RETRIEVE_CEK_SAMPAH_FAIL:
      return [...kendaraans];

    case UPDATE_CEK_SAMPAH_SUCCESS:
      return kendaraans.map((kendaraan) => {
        if (kendaraan.uuid === payload.uuid) {
          return {
            ...kendaraans,
            ...payload,
          };
        } else {
          return kendaraan;
        }
      });

    case DELETE_CEK_SAMPAH_SUCCESS:
      return kendaraans.filter(({ uuid }) => uuid !== payload.uuid);

    default:
      return kendaraans;
  }
}

export default kendaraanReducer;
