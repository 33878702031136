import {
  CREATE_BUKUTAMU_SUCCESS,
  CREATE_BUKUTAMU_FAIL,
  RETRIEVE_BUKUTAMU_SUCCESS,
  RETRIEVE_BUKUTAMU_FAIL,
  UPDATE_BUKUTAMU_SUCCESS,
  UPDATE_BUKUTAMU_FAIL,
  DELETE_BUKUTAMU_SUCCESS,
  DELETE_BUKUTAMU_FAIL,
} from "../actions/types";

const initialState = [];

function bukutamuReducer(bukutamus = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_BUKUTAMU_SUCCESS:
      return [...bukutamus, payload];

    case RETRIEVE_BUKUTAMU_SUCCESS:
      return payload;

    case RETRIEVE_BUKUTAMU_FAIL:
      return [...bukutamus];

    case UPDATE_BUKUTAMU_SUCCESS:
      return bukutamus.map((bukutamu) => {
        if (bukutamu.uuid === payload.uuid) {
          return {
            ...bukutamus,
            ...payload,
          };
        } else {
          return bukutamu;
        }
      });

    case DELETE_BUKUTAMU_SUCCESS:
      return bukutamus.filter(({ uuid }) => uuid !== payload.uuid);

    default:
      return bukutamus;
  }
}

export default bukutamuReducer;
