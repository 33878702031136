import {
  CREATE_KUNJUNGAN_SUCCESS,
  CREATE_KUNJUNGAN_FAIL,
  RETRIEVE_KUNJUNGAN_SUCCESS,
  RETRIEVE_KUNJUNGAN_FAIL,
  UPDATE_KUNJUNGAN_SUCCESS,
  UPDATE_KUNJUNGAN_FAIL,
  DELETE_KUNJUNGAN_SUCCESS,
  DELETE_KUNJUNGAN_FAIL,
} from "../actions/types";

const initialState = [];

function reportReducer(reports = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_KUNJUNGAN_SUCCESS:
      return [...reports, payload];

    case RETRIEVE_KUNJUNGAN_SUCCESS:
      return payload;

    case RETRIEVE_KUNJUNGAN_FAIL:
      return [...reports];

    case UPDATE_KUNJUNGAN_SUCCESS:
      return reports.map((report) => {
        if (report.id === payload.id) {
          return {
            ...reports,
            ...payload,
          };
        } else {
          return report;
        }
      });

    case DELETE_KUNJUNGAN_SUCCESS:
      return reports.filter(({ id }) => id !== payload.id);

    default:
      return reports;
  }
}

export default reportReducer;
