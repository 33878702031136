import {
  CREATE_LOKASI_SUCCESS,
  CREATE_LOKASI_FAIL,
  RETRIEVE_LOKASI_SUCCESS,
  RETRIEVE_LOKASI_FAIL,
  UPDATE_LOKASI_SUCCESS,
  UPDATE_LOKASI_FAIL,
  DELETE_LOKASI_SUCCESS,
  DELETE_LOKASI_FAIL,
} from "../actions/types";

const initialState = [];

function lokasiReducer(lokasis = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LOKASI_SUCCESS:
      return [...lokasis, payload];

    case RETRIEVE_LOKASI_SUCCESS:
      return payload;

    case RETRIEVE_LOKASI_FAIL:
      return [...lokasis];

    case UPDATE_LOKASI_SUCCESS:
      return lokasis.map((lokasi) => {
        if (lokasi.id === payload.id) {
          return {
            ...lokasis,
            ...payload,
          };
        } else {
          return lokasi;
        }
      });

    case DELETE_LOKASI_SUCCESS:
      return lokasis.filter(({ id }) => id !== payload.id);

    default:
      return lokasis;
  }
}

export default lokasiReducer;
