import {
  CREATE_WORKING_BODY_SUCCESS,
  CREATE_WORKING_BODY_FAIL,
  RETRIEVE_WORKING_BODY_SUCCESS,
  RETRIEVE_WORKING_BODY_FAIL,
  UPDATE_WORKING_BODY_SUCCESS,
  UPDATE_WORKING_BODY_FAIL,
  DELETE_WORKING_BODY_SUCCESS,
  DELETE_WORKING_BODY_FAIL,
} from "../actions/types";

const initialState = [];

function workingBodyReducer(workingBodys = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_WORKING_BODY_SUCCESS:
      return [...workingBodys, payload];

    case RETRIEVE_WORKING_BODY_SUCCESS:
      return payload;

    case RETRIEVE_WORKING_BODY_FAIL:
      return [...workingBodys];

    case UPDATE_WORKING_BODY_SUCCESS:
      return workingBodys.map((workingBody) => {
        if (workingBody.id === payload.id) {
          return {
            ...workingBodys,
            ...payload,
          };
        } else {
          return workingBody;
        }
      });

    case DELETE_WORKING_BODY_SUCCESS:
      return workingBodys.filter(({ id }) => id !== payload.id);

    default:
      return workingBodys;
  }
}

export default workingBodyReducer;
