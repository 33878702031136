import {
  CREATE_WAREHOUSE_TYPE_SUCCESS,
  CREATE_WAREHOUSE_TYPE_FAIL,
  RETRIEVE_WAREHOUSE_TYPE_SUCCESS,
  RETRIEVE_WAREHOUSE_TYPE_FAIL,
  UPDATE_WAREHOUSE_TYPE_SUCCESS,
  UPDATE_WAREHOUSE_TYPE_FAIL,
  DELETE_WAREHOUSE_TYPE_SUCCESS,
  DELETE_WAREHOUSE_TYPE_FAIL,
} from "../actions/types";

const initialState = [];

function wareHouseTypeReducer(wareHouseTypes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_WAREHOUSE_TYPE_SUCCESS:
      return [...wareHouseTypes, payload];

    case RETRIEVE_WAREHOUSE_TYPE_SUCCESS:
      return payload;

    case RETRIEVE_WAREHOUSE_TYPE_FAIL:
      return [...wareHouseTypes];

    case UPDATE_WAREHOUSE_TYPE_SUCCESS:
      return wareHouseTypes.map((wareHouseType) => {
        if (wareHouseType.id === payload.id) {
          return {
            ...wareHouseTypes,
            ...payload,
          };
        } else {
          return wareHouseType;
        }
      });

    case DELETE_WAREHOUSE_TYPE_SUCCESS:
      return wareHouseTypes.filter(({ id }) => id !== payload.id);

    default:
      return wareHouseTypes;
  }
}

export default wareHouseTypeReducer;
