import {
  CREATE_WORKING_SAMPAH_SUCCESS,
  CREATE_WORKING_SAMPAH_FAIL,
  RETRIEVE_WORKING_SAMPAH_SUCCESS,
  RETRIEVE_WORKING_SAMPAH_FAIL,
  UPDATE_WORKING_SAMPAH_SUCCESS,
  UPDATE_WORKING_SAMPAH_FAIL,
  DELETE_WORKING_SAMPAH_SUCCESS,
  DELETE_WORKING_SAMPAH_FAIL,
} from "../actions/types";

const initialState = [];

function workingSampahReducer(workingSampahs = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_WORKING_SAMPAH_SUCCESS:
      return [...workingSampahs, payload];

    case RETRIEVE_WORKING_SAMPAH_SUCCESS:
      return payload;

    case RETRIEVE_WORKING_SAMPAH_FAIL:
      return [...workingSampahs];

    case UPDATE_WORKING_SAMPAH_SUCCESS:
      return workingSampahs.map((workingSampah) => {
        if (workingSampah.id === payload.id) {
          return {
            ...workingSampahs,
            ...payload,
          };
        } else {
          return workingSampah;
        }
      });

    case DELETE_WORKING_SAMPAH_SUCCESS:
      return workingSampahs.filter(({ id }) => id !== payload.id);

    default:
      return workingSampahs;
  }
}

export default workingSampahReducer;
