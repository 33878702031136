import {
  CREATE_PARKIR_SUCCESS,
  CREATE_PARKIR_FAIL,
  RETRIEVE_PARKIR_SUCCESS,
  RETRIEVE_PARKIR_FAIL,
  UPDATE_PARKIR_SUCCESS,
  UPDATE_PARKIR_FAIL,
  DELETE_PARKIR_SUCCESS,
  DELETE_PARKIR_FAIL,
} from "../actions/types";

const initialState = [];

function bukutamuReducer(bukutamus = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PARKIR_SUCCESS:
      return [...bukutamus, payload];

    case RETRIEVE_PARKIR_SUCCESS:
      return payload;

    case RETRIEVE_PARKIR_FAIL:
      return [...bukutamus];

    case UPDATE_PARKIR_SUCCESS:
      return bukutamus.map((bukutamu) => {
        if (bukutamu.uuid === payload.uuid) {
          return {
            ...bukutamus,
            ...payload,
          };
        } else {
          return bukutamu;
        }
      });

    case DELETE_PARKIR_SUCCESS:
      return bukutamus.filter(({ uuid }) => uuid !== payload.uuid);

    default:
      return bukutamus;
  }
}

export default bukutamuReducer;
