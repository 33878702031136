import { combineReducers } from "redux";
import auth from "./auth";
import bukutamu from "./bukutamu";
import cekSampah from "./cekSampah";
import cekBox from "./cekBox";
import cekTask from "./cekTask";
import jenisPresensi from "./jenisPresensi";
import jenisKunjungan from "./jenisKunjungan";
import kategori from "./kategori";
import kendaraan from "./kendaraan";
import kunjungan from "./kunjungan";
import lokasi from "./lokasi";
import message from "./message";
import paket from "./paket";
import patroli from "./patroli";
import parkir from "./parkir";
import pegawai from "./pegawai";
import presensi from "./presensi";
import report from "./report";
import reportType from "./reportType";
import unitSatuan from "./unitSatuan";
import wareHouseType from "./wareHouseType";
import workingType from "./workingType";
import workingSampah from "./workingSampah";
import workingBody from "./workingBody";
import role from "./role";
import user from "./user";

export default combineReducers({
  auth,
  bukutamu,
  cekSampah,
  cekBox,
  cekTask,
  jenisPresensi,
  jenisKunjungan,
  kategori,
  kunjungan,
  kendaraan,
  lokasi,
  message,
  paket,
  parkir,
  patroli,
  pegawai,
  presensi,
  report,
  reportType,
  role,
  user,
  unitSatuan,
  wareHouseType,
  workingType,
  workingBody,
  workingSampah,
});
