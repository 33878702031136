import {
  CREATE_WORKING_TYPE_SUCCESS,
  CREATE_WORKING_TYPE_FAIL,
  RETRIEVE_WORKING_TYPE_SUCCESS,
  RETRIEVE_WORKING_TYPE_FAIL,
  UPDATE_WORKING_TYPE_SUCCESS,
  UPDATE_WORKING_TYPE_FAIL,
  DELETE_WORKING_TYPE_SUCCESS,
  DELETE_WORKING_TYPE_FAIL,
} from "../actions/types";

const initialState = [];

function workingTypeReducer(workingTypes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_WORKING_TYPE_SUCCESS:
      return [...workingTypes, payload];

    case RETRIEVE_WORKING_TYPE_SUCCESS:
      return payload;

    case RETRIEVE_WORKING_TYPE_FAIL:
      return [...workingTypes];

    case UPDATE_WORKING_TYPE_SUCCESS:
      return workingTypes.map((workingType) => {
        if (workingType.id === payload.id) {
          return {
            ...workingTypes,
            ...payload,
          };
        } else {
          return workingType;
        }
      });

    case DELETE_WORKING_TYPE_SUCCESS:
      return workingTypes.filter(({ id }) => id !== payload.id);

    default:
      return workingTypes;
  }
}

export default workingTypeReducer;
