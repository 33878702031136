import {
  CREATE_PAKET_SUCCESS,
  CREATE_PAKET_FAIL,
  RETRIEVE_PAKET_SUCCESS,
  RETRIEVE_PAKET_FAIL,
  UPDATE_PAKET_SUCCESS,
  UPDATE_PAKET_FAIL,
  DELETE_PAKET_SUCCESS,
  DELETE_PAKET_FAIL,
} from "../actions/types";

const initialState = [];

function paketReducer(pakets = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PAKET_SUCCESS:
      return [...pakets, payload];

    case RETRIEVE_PAKET_SUCCESS:
      return payload;

    case RETRIEVE_PAKET_FAIL:
      return [...pakets];

    case UPDATE_PAKET_SUCCESS:
      return pakets.map((paket) => {
        if (paket.uuid === payload.uuid) {
          return {
            ...pakets,
            ...payload,
          };
        } else {
          return paket;
        }
      });

    case DELETE_PAKET_SUCCESS:
      return pakets.filter(({ id }) => id !== payload.id);

    default:
      return pakets;
  }
}

export default paketReducer;
