import {
  CREATE_KENDARAAN_SUCCESS,
  CREATE_KENDARAAN_FAIL,
  RETRIEVE_KENDARAAN_SUCCESS,
  RETRIEVE_KENDARAAN_FAIL,
  UPDATE_KENDARAAN_SUCCESS,
  UPDATE_KENDARAAN_FAIL,
  DELETE_KENDARAAN_SUCCESS,
  DELETE_KENDARAAN_FAIL,
} from "../actions/types";

const initialState = [];

function kendaraanReducer(kendaraans = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_KENDARAAN_SUCCESS:
      return [...kendaraans, payload];

    case RETRIEVE_KENDARAAN_SUCCESS:
      return payload;

    case RETRIEVE_KENDARAAN_FAIL:
      return [...kendaraans];

    case UPDATE_KENDARAAN_SUCCESS:
      return kendaraans.map((kendaraan) => {
        if (kendaraan.uuid === payload.uuid) {
          return {
            ...kendaraans,
            ...payload,
          };
        } else {
          return kendaraan;
        }
      });

    case DELETE_KENDARAAN_SUCCESS:
      return kendaraans.filter(({ uuid }) => uuid !== payload.uuid);

    default:
      return kendaraans;
  }
}

export default kendaraanReducer;
