const environment = {
  baseUrl: "https://satukomando.id", //"http://localhost:3009", //https://satukomando.id
  baseAPI: "https://satukomando.id/api-prod", //", //https://satukomando.id/api-prod
};

if (process.env.REACT_APP_ENV === "development") {
  environment.baseUrl = "http://dev.techlab.id";
}

if (process.env.REACT_APP_ENV === "staging") {
  environment.baseUrl = "http://sta.techlab.id";
}

if (process.env.REACT_APP_ENV === "production") {
  environment.baseUrl = "http://prod.techlab.id";
}

export default environment;
