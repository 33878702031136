import {
  CREATE_UNIT_SATUAN_SUCCESS,
  CREATE_UNIT_SATUAN_FAIL,
  RETRIEVE_UNIT_SATUAN_SUCCESS,
  RETRIEVE_UNIT_SATUAN_FAIL,
  UPDATE_UNIT_SATUAN_SUCCESS,
  UPDATE_UNIT_SATUAN_FAIL,
  DELETE_UNIT_SATUAN_SUCCESS,
  DELETE_UNIT_SATUAN_FAIL,
} from "../actions/types";

const initialState = [];

function reportTypeReducer(reportTypes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_UNIT_SATUAN_SUCCESS:
      return [...reportTypes, payload];

    case RETRIEVE_UNIT_SATUAN_SUCCESS:
      return payload;

    case RETRIEVE_UNIT_SATUAN_FAIL:
      return [...reportTypes];

    case UPDATE_UNIT_SATUAN_SUCCESS:
      return reportTypes.map((reportType) => {
        if (reportType.id === payload.id) {
          return {
            ...reportTypes,
            ...payload,
          };
        } else {
          return reportType;
        }
      });

    case DELETE_UNIT_SATUAN_SUCCESS:
      return reportTypes.filter(({ id }) => id !== payload.id);

    default:
      return reportTypes;
  }
}

export default reportTypeReducer;
